







































































.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}
.headIcon {
			img {
				width: 2.4rem;
				height: 2.4rem;
				display: block;
				margin: 0 auto;
			}
		}
.download {
  // background-image: url("../../assets/imgs/share/backGroundImg2.png");
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;

  .shareFooter {
    position: absolute;
    bottom: 0.45rem;
    left: 0;
    width: 100%;

    div {
      width: 50%;
      float: left;

      a {
        display: block;
        margin: auto;
        width: 78%;
        border-radius: 5px;
        height: 0.9rem;
        line-height: 0.9rem;
        font-size: 0.3rem;
      }

      a.iosButton {
        color: #000;
        background: #fff;
      }

      a.androidButton {
        color: #fff;
        background: #5a54ea;
      }

      img {
        width: 0.45rem;
        vertical-align: -0.1rem;
      }
    }
  }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;